
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable, ReplaySubject, of } from 'rxjs';
import { shareReplay, tap, catchError } from 'rxjs/operators';
import { StateStorageService } from './state-storage.service';
import { BaseService } from 'src/app/core/services/base.service';
import { Login } from 'src/app/core/models/login.model';
import { User } from 'src/app/core/models/user.model';
import { UserProfile } from '../../models/user-profile.models';
import {IClientAccount} from "@app/core/models/clients.model";


@Injectable({ providedIn: 'root' })
export class AccountService extends BaseService {
  private userIdentity: User | null = null;
  public authenticationState = new ReplaySubject<User | null>(1);
  private accountCache$?: Observable<User> | null;
  private imageProfileSubject = new BehaviorSubject<string>('assets/images/avatar.png');

  constructor(
    private sessionStorageService: SessionStorageService,
    private stateStorageService: StateStorageService,
    private router: Router,
  ) {
    super('account');
  }

  save(account: Login): Observable<{}> {
    return this.http.post(this.applicationConfigService.getEndpointFor('api/account/registerUser'), account);
  }

  authenticate(identity: User | null): void {
    this.userIdentity = identity;
    this.authenticationState.next(this.userIdentity);
    if (!identity) {
      this.accountCache$ = null;
    }
  }

  hasAnyAuthority(authorities: string[] | string): boolean {
    if (!this.userIdentity) {
      return false;
    }
    if (!Array.isArray(authorities)) {
      authorities = [authorities];
    }
    return this.userIdentity.authorities!.some((authority: string) => authorities.includes(authority));
  }

  identity(force?: boolean): Observable<User | null> {
    if (!this.accountCache$ || force) {
      this.accountCache$ = this.fetch().pipe(
        tap((account: User) => {
          this.authenticate(account);

          if (!this.sessionStorageService.retrieve('locale')) {
           // this.translateService.use(account.langKey);
          }

          this.navigateToStoredUrl();
        }),
        shareReplay()
      );
    }
    return this.accountCache$.pipe(catchError(() => of(null)));
  }

  isAuthenticated(): boolean {
    return this.userIdentity !== null;
  }

  getAuthenticationState(): Observable<User | null> {
    return this.authenticationState.asObservable();
  }

  private fetch(): Observable<User> {
    return this.http.get<User>(this.applicationConfigService.getEndpointFor('api/account'));
  }

  getInfos(): Observable<User> {
    return this.http.get<User>(this.applicationConfigService.getEndpointFor('api/account/get'));
  }

  getUserProfile(): Observable<UserProfile> {
    return this.http.get<UserProfile>(this.applicationConfigService.getEndpointFor('api/account/user/profile'));
  }

  updateUserProfile(data: any): Observable<string> {
    return this.http.put(this.applicationConfigService.getEndpointFor('api/account/user/profile/update'), this.objectToFormData(data), {
      responseType: 'text'
    });
  }

  getUserProfileImage(): Observable<ArrayBuffer> {
    return this.http.get(`${this.applicationConfigService.getEndpointFor('api/account/user/profile/image')}`, {responseType: 'arraybuffer'})
  }

  subImageProfileSubject() {
    return this.imageProfileSubject.asObservable();
  }

  setImageProfileSubject(url: string) {
    this.imageProfileSubject.next(url);
  }

  getClientAccount() {
    return this.http.get<IClientAccount>(`${this.applicationConfigService.getEndpointFor('api/account/client-account')}`);
  }

  private navigateToStoredUrl(): void {
    const previousUrl = this.stateStorageService.getUrl();
    if (previousUrl) {
      this.stateStorageService.clearUrl();
      this.router.navigateByUrl(previousUrl);
    }
  }
}
