import {AuthComponent} from './pages/auth/auth.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './guard/auth.guard';
import {DefaultLayoutComponent} from './layouts/default-layout/default-layout.component';
import {IframeLayoutComponent} from './layouts/iframe-layout/iframe-layout.component';
import {NotFoundComponent} from "@app/pages/error/not-found/not-found.component";
import {subscriptionGuard} from "./guard/subscription.guard";

const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import(`./pages/home/home.module`).then(m => m.HomeModule),
      },
      {
        path: 'accueil',
        redirectTo: '',
        pathMatch: 'full',
      },
      {
        path: 'after-register',
        loadChildren: () => import(`./pages/after-register/after-register.module`).then(m => m.AfterRegisterModule),
      },
      {
        path: 'auth',
        component: AuthComponent,
        loadChildren: () => import(`./pages/auth/auth.module`).then(m => m.AuthModule),
      },
      {
        path: 'account',
        loadChildren: () => import(`./pages/account/account.module`).then(m => m.AccountModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'dashboard',
        loadChildren: () => import(`./pages/dashboard/dashboard.module`).then(m => m.DashboardModule),
        canActivate: [AuthGuard, subscriptionGuard]
      },
      {
        path: 'consultants',
        loadChildren: () => import(`./pages/sourcing/sourcing.module`).then(m => m.SourcingModule),
        canActivate: [AuthGuard, subscriptionGuard]
      },
      {
        path: 'jobs',
        loadChildren: () => import(`./pages/jobs/jobs.module`).then(m => m.JobsModule),
        canActivate: [AuthGuard, subscriptionGuard]
      },
      {
        path: 'parametres',
        loadChildren: () => import(`./pages/parametre/parametre.module`).then(m => m.ParametreModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'stripe/callback',
        children: [
          {
            path: 'success',
            redirectTo: '/parametres/subscriptions?action=register&status=success',
            pathMatch: 'full',
          },
          {
            path: 'error',
            redirectTo: '/parametres/subscriptions?action=register&status=error',
            pathMatch: 'full',
          },
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'notifications',
        loadChildren: () => import(`./pages/notifications/notifications.module`).then(m => m.NotificationsModule),
        // canActivate: [AuthGuard]
      },
      {
        path: '',
        redirectTo: '/accueil',
        pathMatch: 'full'
      },
      {
        path: 'admin',
        loadChildren: () => import(`./pages/admin/admin.module`).then(m => m.AdminModule),
        // canActivate: [AuthGuard]
      },
    ]
  },
  {
    path: 'iframe',
    component: IframeLayoutComponent,
    loadChildren: () => import(`./pages/iframe/iframe.module`).then(m => m.IframeModule),
    canActivate: []
  },
  {
    path: '**',
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        component: NotFoundComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
