import {Component, OnInit} from '@angular/core';
import {KeycloakService} from "keycloak-angular";
import {AuthorityEnum} from "@app/core/enumerations/authority-enums";
import {interval} from "rxjs";
import {mergeMap} from "rxjs/operators";
import {NotificationService} from "@app/core/services/notification/notification.service";
import {AccountService} from "@app/core/services/auth-services/account.service";
import {DomSanitizer} from "@angular/platform-browser";
import {UserProfile} from "@app/core/models/user-profile.models";
import {BillingService} from "@app/core/services/billing/billing.service";
import {CurrentSubscription} from "@app/core/models/current-subscription.models";
import {IClientAccount} from "@app/core/models/clients.model";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isConnected: boolean = false;
  notificationCount: number = 0;
  user!: UserProfile;
  currentUrl: any = 'assets/images/avatar.png';
  currentPlan: CurrentSubscription | null = null;
  clientAccount!: IClientAccount;

  constructor(
    private keycloakService: KeycloakService,
    private accountService: AccountService,
    private sanitizer: DomSanitizer,
    private notificationService: NotificationService,
    private billingService: BillingService,
  ) {
    this.accountService.getClientAccount().subscribe((data) => {
      this.clientAccount = data;
    });
  }

  ngOnInit(): void {
    this.isConnected = Boolean(this.keycloakService.getKeycloakInstance().authenticated);

    if (this.isConnected) {
      this.initUnreadNotificationsCount();
      this.getUserProfileData();
      this.getImageProfile();
      this.getCurrentSubscription();
    }
  }

  getImageProfile() {
    this.accountService.getUserProfileImage().subscribe({
      next: (userImage: ArrayBuffer) => {
        if (userImage) {
          const imageBlob = new Blob([userImage], {type: 'image/*'});
          const imageUrl = URL.createObjectURL(imageBlob);
          this.currentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
        }
      },
      error: (errorResponse: any) => console.log('ERROR!!! ', errorResponse)
    });
  }

  getUserProfileData() {
    this.accountService.getUserProfile().subscribe((data) => {
      this.user = data;
    });
  }

  initUnreadNotificationsCount() {
    interval(30 * 1000) // toutes les 30 secondes
      .pipe(
        mergeMap(() => this.notificationService.getNotReadNumber())
      )
      .subscribe(num => this.notificationCount = num);
  }

  getCurrentSubscription() {
    this.billingService.getCurrentSubscription().subscribe({
      next: (data: any) => {
        this.currentPlan = data;
      }
    });
  }

  computeRemainingDays(): number {
    if (!this.currentPlan || !this.currentPlan.expiryDate) {
      return 0;
    }
    const expiringDate = new Date(this.currentPlan.expiryDate);
    const timeDifference = expiringDate.getTime() - new Date().getTime();
    return Math.floor(timeDifference / (1000 * 3600 * 24));
  }

  login() {
    this.keycloakService.login({redirectUri: `${window.location.origin}/auth/callback/keycloak/login?returnUrl=${window.location.pathname}`});
  }

  register() {
    this.keycloakService.register({redirectUri: `${window.location.origin}/auth/callback/keycloak/register?returnUrl=${window.location.pathname}`});
  }

  logout() {
    this.keycloakService.logout(window.location.origin);
  }

  protected readonly authorityEnum = AuthorityEnum;
  protected readonly window = window;
}
