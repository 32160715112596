import {inject} from '@angular/core';
import {AccountService} from "@app/core/services/auth-services/account.service";
import {catchError, map} from "rxjs/operators";
import {of} from "rxjs";
import {Router} from "@angular/router";

export const subscriptionGuard = () => {
  const router = inject(Router);
  const accountService = inject(AccountService);

  return accountService.getClientAccount().pipe(
    map(response => response.accountActive || router.parseUrl('/')),
    catchError(() => of(router.parseUrl('/')))
  );
}
