import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { Observable } from 'rxjs';
import {IPlanChangeRequest} from "@app/core/models/plan-change-requests.models";

@Injectable({
  providedIn: 'root'
})
export class BillingService extends BaseService {

  constructor() {
    super('account');
  }

  getPlanPackages(): Observable<any[]> {
    return this.http.get<any[]>(`${this.resourceUrl}/plan-packages/default`);
  }

  getCurrentSubscription(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/subscriptions/current`);
  }

  getAllPlanChangeRequests(): Observable<any> {
    return this.http.get<IPlanChangeRequest>(`${this.resourceUrl}/subscriptions/plan-change-requests`);
  }

  getAllPaymentTransactions(): Observable<any[]> {
    return this.http.get<any[]>(`${this.resourceUrl}/subscriptions/payments`);
  }

  requestPackageSubscription(planId: number): Observable<string> {
    return this.http.post(`${this.resourceUrl}/subscriptions/new/${planId}`, {}, {responseType: 'text'});
  }

  changePackageSubscription(planId: number): Observable<string> {
    return this.http.post(`${this.resourceUrl}/subscriptions/change/${planId}`, {}, {responseType: 'text'});
  }

  cancelPackageSubscription(): Observable<string> {
    return this.http.post(`${this.resourceUrl}/subscriptions/cancel`, {}, {responseType: 'text'});
  }

  finalizePlanChangeRequest() : Observable<string> {
    return this.http.post(`${this.resourceUrl}/subscriptions/plan-change-request/finalize`, {}, {responseType: 'text'});
  }

  cancelPlanChangeRequest(): Observable<any> {
    return this.http.post(`${this.resourceUrl}/subscriptions/plan-change-request/cancel`, {});
  }
}
