export const environment = {
  production: true,
  api: {
    sourcingBaseUrl: 'https://dev-backend.match-it.fr'
  },
  Keycloak: {
    url: 'https://dev-sso.match-it.fr/auth',
    realm: 'matchit',
    clientId: 'match-it-front'
  },
  contactEmail: 'support@matchit.fr',
  appUrl: 'https://dev.match-it.fr/',
  ia: {
    active:"true"
  }
};
